<template>
  <v-container fluid>
    <v-row>
      <v-text-field
        :label="$t('tag.fields.lat')"
        prepend-icon="mdi-map-marker"
        type="text"
        :rules="coordinateRules"
        v-model="item.latitude"
      />
      <v-text-field
        :label="$t('tag.fields.long')"
        prepend-icon="mdi-map-marker"
        type="text"
        :rules="coordinateRules"
        v-model="item.longitude"
      />
      <v-btn small icon @click="clearPosition">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <pick-location
        v-bind:resource="item"
        v-on:update-pos="updatePosition"
        leaflet
      />
    </v-row>
  </v-container>
</template>

<script>
import PickLocation from "./PickLocation.vue";
export default {
  name: "CoordinatePicker",

  props: {
    item: {
      type: Object,
      default: () => {
        return { latitude: null, longitude: null };
      },
    },
  },

  data() {
    return {
      coordinateRules: [(v) => !v || /\d+\.\d+/.test(v)],
    };
  },

  components: {
    PickLocation,
  },

  methods: {
    updatePosition(pos) {
      this.item.latitude = pos.latitude;
      this.item.longitude = pos.longitude;

      this.$emit("update", {
        latitude: pos.latitude,
        longitude: pos.longitude,
      });
    },

    clearPosition() {
      this.item.latitude = null;
      this.item.longitude = null;

      this.$emit("update", { latitude: null, longitude: null });
    },
  },
  mounted() {
    this.item.latitude = this.item.hasOwnProperty("latitude")
      ? this.item.latitude
      : null;
    this.item.longitude = this.item.hasOwnProperty("longitude")
      ? this.item.longitude
      : null;
  },
};
</script>
