<template>
  <v-card>
    <v-stepper v-model="step" vertical>
      <v-stepper-step :complete="step > steps.SELECT_NODE_TYPE" step="1">
        {{ $t("tag.createSteps.step1") }}
      </v-stepper-step>
      <v-stepper-items>
        <v-stepper-content step="1">
          <device-type-select v-model="type" compact></device-type-select>
          <v-form
            :lazy-validation="true"
            @submit.prevent="handleSubmit"
            v-model="valid"
            ref="form"
          >
            <v-text-field
              :label="$t('tag.fields.name')"
              name="name"
              prepend-icon="mdi-form-textbox"
              type="text"
              v-model="name"
              :rules="nameRules"
              class="required"
              :placeholder="nodeAliasPlaceholder"
            ></v-text-field>

            <div class="d-flex justify-space-between">
              <byte-input
                :label="$t('tag.fields.deveui')"
                v-model="deveui"
                class="required"
                v-if="type == nodeTypes.LORAWAN"
              />

              <v-text-field
                v-else
                :label="$t('tag.fields.id')"
                name="did"
                prepend-icon="mdi-fingerprint"
                type="text"
                v-model="deveui"
                :rules="idRules"
                :error-messages="deveuiError"
                class="required"
              ></v-text-field>

              <v-btn
                text
                color="warning"
                class="align-self-center"
                @click="generateDeveui"
                v-if="type != 0"
                >{{ $t("tag.generateId") }}
              </v-btn>
            </div>
            <coordinate-picker
              :item="coordinatePickerInput"
              v-on:update="updatePosition"
            />
          </v-form>

          <v-row v-if="type == nodeTypes.LORAWAN || type == nodeTypes.API">
            <tag-csv-upload v-model="csvUploadedTags" />
            <v-container fluid>
              <v-row
                v-for="(d, i) of csvUploadedTags"
                :key="i"
                class="node-row"
                :style="
                  i + 1 > nodesLeftForCompany
                    ? 'background: rgba(255, 0, 0, 0.2)'
                    : ''
                "
              >
                <v-col cols="12" v-if="i + 1 > nodesLeftForCompany">
                  <strong>NOTICE: </strong>
                  {{ $t("license.limitations.errors.tag") }}
                </v-col>
                <v-col :cols="6">
                  <v-text-field
                    :label="$t('tag.fields.name')"
                    prepend-icon="mdi-form-textbox"
                    v-model="d.name"
                    class="required"
                  ></v-text-field>
                </v-col>
                <v-col :cols="type == nodeTypes.LORAWAN ? 6 : 5">
                  <byte-input
                    v-if="type == nodeTypes.LORAWAN"
                    :label="$t('tag.fields.deveui')"
                    v-model="d.deveui"
                    class="required"
                  />
                  <v-text-field
                    v-else
                    :label="$t('tag.fields.deveui')"
                    prepend-icon="mdi-form-textbox"
                    v-model="d.deveui"
                    class="required"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" v-if="type == nodeTypes.LORAWAN">
                  <byte-input
                    :label="$t('tag.fields.appKey')"
                    prepend-icon="mdi-key-link"
                    v-model="d.appKey"
                    :bytes="16"
                    :required="false"
                  />
                </v-col>
                <v-col cols="6" v-if="type == nodeTypes.LORAWAN">
                  <byte-input
                    :label="$t('tag.fields.appEui')"
                    prepend-icon="mdi-key-link"
                    v-model="d.appEui"
                    :bytes="8"
                    :required="false"
                  />
                </v-col>
                <v-col cols="6" v-if="type == nodeTypes.LORAWAN">
                  <DecoderSelect v-model="d.decoder" />
                </v-col>

                <v-col cols="4" v-if="type == nodeTypes.LORAWAN">
                  <v-switch
                    name="classC"
                    prepend-icon="mdi-alpha-c-circle-outline"
                    v-model="d.classC"
                    label="Class C Device"
                  ></v-switch>
                </v-col>

                <v-col :cols="type == nodeTypes.LORAWAN ? 2 : 1">
                  <v-btn icon fab @click="removeCsvTag(i)" color="error">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-row>

          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="moveStep"
              x-large
              :disabled="!canMoveStep"
            >
              {{ $t("common.next") }}
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-row>
        </v-stepper-content>

        <v-stepper-step :complete="step > steps.CHOOSE_APPLICATION" step="2">
          {{ $t("tag.createSteps.step2") }}
        </v-stepper-step>

        <v-stepper-content step="2">
          <!-- Display selectionsheets for all the applications and a extra selectionsheet to create a new application -->
          <application-selector v-model="applicationId"></application-selector>

          <v-row>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="moveBackStep" x-large>
              <v-icon left>mdi-arrow-left</v-icon>
              {{ $t("common.previousStep") }}
            </v-btn>

            <v-btn
              color="primary"
              text
              @click="moveStep"
              x-large
              :disabled="!canMoveStep"
            >
              {{ $t("common.next") }}
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-step
        :complete="step > steps.CHOOSE_DECODER"
        step="3"
        v-if="type == nodeTypes.LORAWAN"
      >
        {{ $t("tag.createSteps.step3") }}
      </v-stepper-step>

      <v-stepper-content step="3" v-if="type == nodeTypes.LORAWAN">
        <v-container>
          <DecoderSelect :advanced="true" v-model="decoder" />

          <v-row>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="moveBackStep" x-large>
              <v-icon left>mdi-arrow-left</v-icon>
              {{ $t("common.previousStep") }}
            </v-btn>

            <v-btn
              color="primary"
              text
              @click="moveStep"
              x-large
              :disabled="!canMoveStep"
            >
              {{ $t("common.next") }}
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-stepper-content>

      <v-stepper-step
        :complete="step > steps.SET_KEYS"
        step="4"
        v-if="type == nodeTypes.LORAWAN"
      >
        {{ $t("tag.createSteps.step4") }}
      </v-stepper-step>

      <v-stepper-step
        :complete="step > steps.SELECT_SPOTIO"
        step="4"
        v-if="type == nodeTypes.SPOTIO"
      >
        {{ $t("tag.createSteps.spotIo4") }}
      </v-stepper-step>

      <v-stepper-step
        :complete="step > steps.REGISTER_MODBUS"
        step="4"
        v-if="type == nodeTypes.MODBUS"
      >
        {{ $t("tag.createSteps.modbus4") }}
      </v-stepper-step>

      <v-stepper-step
        :complete="step > steps.SETUP_OPC"
        step="4"
        v-if="type == nodeTypes.OPC"
      >
        {{ $t("tag.createSteps.opc") }}
      </v-stepper-step>

      <v-stepper-content step="4" v-if="type == nodeTypes.LORAWAN">
        <v-card-text>
          <byte-input
            :label="$t('tag.fields.appKey')"
            v-model="appKey"
            icon="mdi-key-link"
            :bytes="16"
            :required="false"
          />
        </v-card-text>

        <v-card-text>
          <byte-input
            :label="$t('tag.fields.appEui')"
            v-model="appEui"
            icon="mdi-key-link"
            :bytes="8"
            :required="false"
          />
        </v-card-text>

        <v-switch
          name="classC"
          prepend-icon="mdi-alpha-c-circle-outline"
          v-model="classC"
          label="Class C Device"
        ></v-switch>

        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="moveBackStep" x-large>
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t("common.previousStep") }}
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="moveStep"
            x-large
            :disabled="!canMoveStep"
          >
            {{ $t("common.next") }}
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-row>
      </v-stepper-content>

      <v-stepper-content step="4" v-if="type == nodeTypes.SPOTIO">
        <v-card-text>
          <spot-io-form v-model="spotIos" :deveui="deveui" />
        </v-card-text>

        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="moveBackStep" x-large>
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t("common.previousStep") }}
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="moveStep"
            x-large
            :disabled="!canMoveStep"
          >
            {{ $t("common.next") }}
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-row>
      </v-stepper-content>

      <v-stepper-content step="4" v-if="type == nodeTypes.MODBUS">
        <modbus-register-form v-model="modbusRegisters" />

        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="moveBackStep" x-large>
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t("common.previousStep") }}
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="moveStep"
            x-large
            :disabled="!canMoveStep"
          >
            {{ $t("common.next") }}
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-row>
      </v-stepper-content>

      <v-stepper-content step="4" v-if="type == nodeTypes.OPC">
        <opc-node-form v-model="opcNodes" monitoring-node :deveui="deveui" />

        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="moveBackStep" x-large>
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t("common.previousStep") }}
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="moveStep"
            x-large
            :disabled="!canMoveStep"
          >
            {{ $t("common.next") }}
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-row>
      </v-stepper-content>

      <v-stepper-step
        :complete="step >= steps.MQTT"
        step="5"
        v-if="type == nodeTypes.MQTT || type == nodeTypes.SPARKPLUG"
      >
        {{ $t("tag.createSteps.step5") }}
      </v-stepper-step>

      <v-stepper-content
        step="5"
        v-if="type == nodeTypes.MQTT || type == nodeTypes.SPARKPLUG"
      >
        <broker-selector
          v-if="type == nodeTypes.MQTT"
          v-model="mqttMeta.brokerId"
        />
        <mqtt-meta-form
          v-model="mqttMeta"
          :broker="mqttMeta.brokerId"
          :deveui="deveui"
          :node-type="type"
          v-if="type == nodeTypes.MQTT"
        />

        <sparkplug-form
          v-if="type == nodeTypes.SPARKPLUG"
          v-model="sparkplugMeta"
        />
        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="moveBackStep" x-large>
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t("common.previousStep") }}
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="moveStep"
            x-large
            :disabled="!canMoveStep"
          >
            {{ $t("common.next") }}
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-row>
      </v-stepper-content>

      <v-stepper-step :complete="step >= steps.ATTRIBUTES" step="6">
        {{ $t("tag.createSteps.step6") }}
      </v-stepper-step>

      <v-stepper-content step="6">
        <attribute-form use-selection-sheet v-model="attributes" />

        <v-row>
          <v-spacer />
          <v-btn color="grey" text @click="moveBackStep" x-large>
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t("common.previousStep") }}
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="moveStep"
            x-large
            :disabled="!canMoveStep"
          >
            {{ $t("common.next") }}
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-row>
      </v-stepper-content>

      <v-stepper-step :complete="step >= steps.DONE" step="7">
        {{ $t("tag.createSteps.finish") }}
      </v-stepper-step>

      <v-stepper-content step="7">
        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="moveBackStep" x-large>
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t("common.previousStep") }}
          </v-btn>
        </v-row>
      </v-stepper-content>
    </v-stepper>

    <v-card-actions>
      <v-btn
        color="primary"
        type="button"
        @click="handleSubmit"
        x-large
        :disabled="!canSave"
        >{{ $t("common.create") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ApplicationSelector from "@/components/application/ApplicationSelector.vue";
import BrokerSelector from "@/components/mqtt/BrokerSelector";
import ByteInput from "../common/ByteInput.vue";
import CsharpEnum from "../../_helpers/CsharpEnum";
import crypto from "crypto";
import DecoderSelect from "@/components/tag/DecoderSelect.vue";
import DeviceTypeSelect from "@/components/tag/DeviceTypeSelect";
import i18n from "@/plugins/i18n";
import MqttMetaForm from "@/components/mqtt/MqttMetaForm";
import TagCsvUpload from "@/components/tag/TagCsvUpload";
import SpotIoForm from "@/components/tag/SpotIoForm";
import SparkplugForm from "../mqtt/SparkplugForm.vue";
import ModbusRegisterForm from "../modbus/ModbusRegisterForm.vue";
import OpcNodeForm from "@/components/opc/OpcNodeForm.vue";
import { companySettingKeys } from "@/_helpers/companySettings";
import AttributeForm from "./AttributeForm.vue";
import CoordinatePicker from "../common/CoordinatePicker.vue";

const steps = {
  SELECT_NODE_TYPE: 1,
  CHOOSE_APPLICATION: 2,
  CHOOSE_DECODER: 3,
  SET_KEYS: 4,
  SELECT_SPOTIO: 4,
  REGISTER_MODBUS: 4,
  SETUP_OPC: 4,
  MQTT: 5,
  ATTRIBUTES: 6,
  DONE: 7,
};

const stepOrder = [
  //indexes must match nodeTypes
  [
    //LORAWAN: 0
    steps.SELECT_NODE_TYPE,
    steps.CHOOSE_APPLICATION,
    steps.CHOOSE_DECODER,
    steps.SET_KEYS,
    steps.ATTRIBUTES,
    steps.DONE,
  ],
  [
    //API: 1
    steps.SELECT_NODE_TYPE,
    steps.CHOOSE_APPLICATION,
    steps.ATTRIBUTES,
    steps.DONE,
  ],
  [
    //MQTT: 2
    steps.SELECT_NODE_TYPE,
    steps.CHOOSE_APPLICATION,
    steps.MQTT,
    steps.ATTRIBUTES,
    steps.DONE,
  ],
  [
    //NODERED: 3
    steps.SELECT_NODE_TYPE,
    steps.CHOOSE_APPLICATION,
    steps.ATTRIBUTES,
    steps.DONE,
  ],
  [
    //SPARKPLUG: 4
    steps.SELECT_NODE_TYPE,
    steps.CHOOSE_APPLICATION,
    steps.MQTT,
    steps.ATTRIBUTES,
    steps.DONE,
  ],
  [
    //SPOTIO: 5
    steps.SELECT_NODE_TYPE,
    steps.CHOOSE_APPLICATION,
    steps.SPOTIO,
    steps.ATTRIBUTES,
    steps.DONE,
  ],
  [
    //MODBUS: 6
    steps.SELECT_NODE_TYPE,
    steps.CHOOSE_APPLICATION,
    steps.MODBUS,
    steps.ATTRIBUTES,
    steps.DONE,
  ],
  [
    //OPC: 7
    steps.SELECT_NODE_TYPE,
    steps.CHOOSE_APPLICATION,
    steps.OPC,
    steps.ATTRIBUTES,
    steps.DONE,
  ],
];

export default {
  name: "TagCreate",
  components: {
    OpcNodeForm,
    ApplicationSelector,
    BrokerSelector,
    ByteInput,
    DecoderSelect,
    DeviceTypeSelect,
    MqttMetaForm,
    TagCsvUpload,
    SpotIoForm,
    SparkplugForm,
    ModbusRegisterForm,
    AttributeForm,
    CoordinatePicker,
  },

  data() {
    return {
      maxSteps: steps.DONE,
      submitted: false,
      step: 1,
      valid: false,
      deveui: "",
      appKey: "",
      appEui: "",
      name: "",
      latitude: null,
      longitude: null,
      applicationId: null,
      classC: false,
      decoder: "",
      deveuiError: "",
      type: 0,
      stepIndex: 0,
      mqttMeta: {
        brokerId: null,
        publishTopic: "",
        topics: [],
      },
      spotIos: [],
      modbusRegisters: [],
      opcNodes: [],
      attributes: [],
      sparkplugMeta: {
        brokerId: null,
        groupId: "",
        edgeId: "",
      },
      csvUploadedTags: [],
      nameRules: [
        (v) =>
          !!v || this.$t("validation.required", [this.$t("tag.fields.name")]),
        (v) =>
          (v && v.length <= 255) ||
          this.$t("validation.max", [this.$t("tag.fields.name"), 255]),
      ],
      deveuiRules: [
        (v) =>
          !!v || this.$t("validation.required", [this.$t("tag.fields.deveui")]),
        (v) =>
          (v && v.length <= 16) ||
          this.$t("validation.max", [this.$t("tag.fields.deveui"), 16]),
      ],
      idRules: [
        (v) =>
          !!v || this.$t("validation.required", [this.$t("tag.fields.deveui")]),
        (v) =>
          (v && v.length <= 75) ||
          this.$t("validation.max", [this.$t("tag.fields.deveui"), 75]),
      ],
    };
  },

  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),

    ...mapState("companies", {
      companyApps: "companyApps",
      appsLoad: "status.loading",
    }),

    ...mapState("tag", ["apiErrors"]),
    ...mapState("configuration", ["nodesLeftForCompany"]),
    ...mapGetters("configuration", ["getCompanySetting"]),

    nodeTypes() {
      return CsharpEnum.NodeTypes;
    },

    steps() {
      return steps;
    },

    nodeAliasPlaceholder() {
      const nodeAlias = this.getCompanySetting(companySettingKeys.nodeAlias);
      return nodeAlias ? `e.g, ${nodeAlias}` : "";
    },

    canMoveStep() {
      switch (this.step) {
        case this.nodeTypes.LORAWAN:
          switch (this.step) {
            case steps.SELECT_NODE_TYPE:
              if (
                this.deveui != "" &&
                this.deveui &&
                this.name != "" &&
                this.name &&
                this.csvUploadedTags.length == 0 &&
                this.$refs.form.validate()
              )
                return true;

              if (this.csvUploadedTags.length > 0) return true;
              return false;
            case steps.CHOOSE_APPLICATION:
              return this.applicationId != "" && this.applicationId;
            default:
              return true;
          }

        case this.nodeTypes.API:
          switch (this.step) {
            case steps.SELECT_NODE_TYPE:
              if (
                this.deveui != "" &&
                this.deveui &&
                this.name != "" &&
                this.name &&
                this.csvUploadedTags.length == 0 &&
                this.$refs.form.validate()
              )
                return true;

              if (this.csvUploadedTags.length > 0) return true;
              return false;
            case steps.CHOOSE_APPLICATION:
              return this.applicationId != "" && this.applicationId;
            default:
              return true;
          }

        case this.nodeTypes.MQTT:
          switch (this.step) {
            case steps.SELECT_NODE_TYPE:
              if (
                this.deveui != "" &&
                this.deveui &&
                this.name != "" &&
                this.name
              )
                return true;
              return false;
            case steps.CHOOSE_APPLICATION:
              return this.applicationId != "" && this.applicationId;
            default:
              return true;
          }

        case this.nodeTypes.NODERED:
          switch (this.step) {
            case steps.SELECT_NODE_TYPE:
              if (
                this.deveui != "" &&
                this.deveui &&
                this.name != "" &&
                this.name
              )
                return true;
              return false;
            case steps.CHOOSE_APPLICATION:
              return this.applicationId != "" && this.applicationId;
            default:
              return true;
          }

        case this.nodeTypes.SPARKPLUG:
          switch (this.step) {
            case steps.SELECT_NODE_TYPE:
              if (
                this.deveui != "" &&
                this.deveui &&
                this.name != "" &&
                this.name
              )
                return true;
              return false;
            case steps.CHOOSE_APPLICATION:
              return this.applicationId != "" && this.applicationId;
            case steps.MQTT:
              if (
                this.sparkplugMeta.brokerId != null &&
                this.sparkplugMeta.brokerId &&
                this.sparkplugMeta.groupId.trim() != "" &&
                this.sparkplugMeta.groupId &&
                this.sparkplugMeta.edgeId.trim() != "" &&
                this.sparkplugMeta.edgeId
              )
                return true;
              return false;
            default:
              return true;
          }

        case this.nodeTypes.SPOTIO:
          switch (this.step) {
            case steps.SELECT_NODE_TYPE:
              if (
                this.deveui != "" &&
                this.deveui &&
                this.name != "" &&
                this.name
              )
                return true;
              return false;
            case steps.CHOOSE_APPLICATION:
              return this.applicationId != "" && this.applicationId;
            case steps.SELECT_SPOTIO:
              return this.spotIos.length > 0;
            default:
              return true;
          }

        case this.nodeTypes.MODBUS:
          switch (this.step) {
            case steps.SELECT_NODE_TYPE:
              if (
                this.deveui != "" &&
                this.deveui &&
                this.name != "" &&
                this.name
              )
                return true;
              return false;
            case steps.CHOOSE_APPLICATION:
              return this.applicationId != "" && this.applicationId;
            case steps.REGISTER_MODBUS:
              return this.modbusRegisters.length > 0;
            default:
              return true;
          }

        case this.nodeTypes.OPC:
          switch (this.step) {
            case steps.SELECT_NODE_TYPE:
              if (
                this.deveui != "" &&
                this.deveui &&
                this.name != "" &&
                this.name
              )
                return true;
              return false;
            case steps.CHOOSE_APPLICATION:
              return this.applicationId != "" && this.applicationId;
            case steps.SETUP_OPC:
              return this.opcNodes.length > 0;
            default:
              return true;
          }
        default:
          return false;
      }
    },

    canSave() {
      return this.step >= this.maxSteps;
    },

    coordinatePickerInput() {
      return {
        latitude: this.latitude,
        longitude: this.longitude,
        name: this.name,
      };
    },
  },
  methods: {
    ...mapActions("tag", ["createTag"]),
    ...mapActions("companies", ["getCompanyApplications"]),
    ...mapActions("mqtt", ["createMqttMeta"]),
    ...mapActions("configuration", [
      "getCanCreateBools",
      "getNodesLeftForCompany",
    ]),

    removeCsvTag(index) {
      this.csvUploadedTags.splice(index, 1);
    },

    updatePosition(pos) {
      this.latitude = pos.latitude;
      this.longitude = pos.longitude;
    },

    moveStep() {
      if (!this.canMoveStep) return;
      this.stepIndex++;
      if (
        this.type === this.nodeTypes.LORAWAN &&
        this.step === steps.CHOOSE_APPLICATION &&
        this.csvUploadedTags.length > 0
      ) {
        this.stepIndex = stepOrder[0].length - 2;
      }
      this.step = stepOrder[this.type][this.stepIndex];
    },

    moveBackStep() {
      this.stepIndex--;
      if (
        this.type === this.nodeTypes.LORAWAN &&
        this.step === steps.ATTRIBUTES &&
        this.csvUploadedTags.length > 0
      ) {
        this.stepIndex = 1;
      }
      this.step = stepOrder[this.type][this.stepIndex];
    },

    parseErrors() {
      // Clear old validation errors
      this.deveuiError = "";
      // Check for new validation errors, apiErrors have capitalized keys
      for (let error in this.apiErrors) {
        if (error === "Deveui") {
          this.deveuiError = i18n.t("validation.deveuiConflict");
        }
      }
    },

    generateDeveui() {
      this.deveui = crypto.randomBytes(8).toString("hex");
    },

    async handleSubmit() {
      this.submitted = true;

      if (this.csvUploadedTags.length <= 0) {
        const {
          deveui,
          name,
          profile,
          latitude,
          longitude,
          appKey,
          appEui,
          decoder,
          classC,
          type,
          spotIos,
          modbusRegisters,
          opcNodes,
          attributes,
        } = this;

        let payload = {
          deveui,
          name,
          profile,
          latitude,
          longitude,
          appKey,
          appEui,
          decoder,
          classC,
          nodeType: type,
          spotIos,
          modbusRegisters,
          opcNodes,
          attributes,
        };

        if (type === this.SPARKPLUG) payload.sparkplugMeta = this.sparkplugMeta;

        await this.uploadNode(payload);

        // Upload TagMeta
        if (this.mqttMeta.brokerId != null) {
          await this.createMqttMeta({
            brokerId: this.mqttMeta.brokerId,
            deveui: this.deveui,
            payload: this.mqttMeta,
          });
        }
      } else {
        for (let tag of this.csvUploadedTags) {
          let payload = {
            deveui: tag.deveui,
            appKey: tag.appKey,
            appEui: tag.appEui,
            name: tag.name,
            decoder: tag.decoder,
            profile: this.profile,
            classC: tag.classC,
            attributes: this.attributes,
          };

          await this.uploadNode(payload);
        }
      }

      this.$router.push("/tag");
    },

    async uploadNode(payload) {
      this.submitted = true;
      if (payload.appKey != "" && payload.appKey)
        payload.appKey = payload.appKey.replace(/ /g, "").trim();
      if (payload.appEui != "" && payload.appEui)
        payload.appEui = payload.appEui.replace(/ /g, "").trim();

      payload.nodeType = this.type;
      return await this.createTag({
        applicationId: this.applicationId,
        payload: payload,
      }).then(() => {
        if (this.apiErrors.length >= 0) {
          return this.parseErrors();
        }
      });
    },
  },

  async created() {
    await this.getCanCreateBools();
    await this.getNodesLeftForCompany();
    if (!this.hasCompany) this.$router.push("/company/create");
    if (this.$route.params.deveui) this.deveui = this.$route.params.deveui;
  },
};
</script>

<style>
.required label::after {
  content: " *";
}

.node-row {
  border-radius: 10px;
  border: 1px solid rgb(216, 216, 216);
  color: black;
  max-width: 100%;
  margin: 0;
  margin-top: 1rem;
}
</style>
