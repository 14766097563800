<template>
  <v-container fluid>
    <h3 class="mb-4">{{ $t("tag.attributes.title") }}</h3>

    <v-row>
      <v-form
        :lazy-validation="false"
        @submit.prevent="save"
        ref="attributesForm"
        class="d-flex justify-space-between align-center"
        style="width: 100%"
      >
        <v-combobox
          :label="$t('tag.attributes.name')"
          :items="companyTagAttributes"
          item-value="name"
          item-text="name"
          v-model="name"
          small-chips
        />

        <v-text-field
          :label="$t('tag.attributes.value')"
          autocomplete="off"
          name="attributeValue"
          type="text"
          v-model="attributeValue"
          prepend-icon="mdi-arrow-right-bold-outline"
          :rules="[rules.requiredValue]"
        />

        <v-btn color="primary" type="submit" text>{{ $t("common.add") }}</v-btn>
      </v-form>
    </v-row>
    <v-row v-if="localValue.length > 0">
      <v-data-table
        v-if="!useSelectionSheet"
        :headers="headers"
        :items="localValue"
        :items-per-page="5"
      />

      <selection-sheet
        v-else
        :key="i"
        v-for="(v, i) in localValue"
        :item="v"
        item-key="name"
        :label="v.name + ': ' + v.value"
        font-size="1rem"
        deleteable
        disable
        add-margin
        hide-icon
        @click="
          () => {
            name = v.name;
            attributeValue = v.value;
          }
        "
        @delete="remove(i)"
        small
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { normalize } from "@/_helpers/tagHelper";
import SelectionSheet from "../common/SelectionSheet.vue";

export default {
  components: { SelectionSheet },

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    useSelectionSheet: {
      type: Boolean,
      default: true,
    },
  },

  model: { prop: "value" },

  emits: ["input"],

  data() {
    return {
      valid: false,
      name: "",
      attributeValue: "",
      rules: {
        requiredName: (v) =>
          !!v ||
          this.$t("validation.required", [this.$t("tag.attributes.name")]),
        requiredValue: (v) =>
          !!v ||
          this.$t("validation.required", [this.$t("tag.attributes.value")]),
      },
      headers: [
        { text: this.$t("tag.attributes.name"), value: "name" },
        { text: this.$t("tag.attributes.value"), value: "attributeValue" },
      ],
    };
  },

  computed: {
    ...mapState("tag", ["tagsAttributes"]),

    companyTagAttributes() {
      return this.tagsAttributes.map((x) => {
        return x.name;
      });
    },

    localValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    ...mapActions("tag", ["getAllTagAttributes"]),

    remove(idx) {
      this.localValue.splice(idx, 1);
    },

    save() {
      this.valid = this.$refs.attributesForm.validate();
      if (!this.valid) {
        return;
      }

      let nName = normalize(this.name);
      let idx = this.localValue.findIndex((d) => d.normalizedName === nName);
      if (idx === -1) {
        this.localValue.push({
          name: this.name,
          normalizedName: nName,
          value: this.attributeValue,
        });

        return;
      }

      this.localValue[idx].value = this.attributeValue;
    },
  },

  async created() {
    await this.getAllTagAttributes();
  },
};
</script>
