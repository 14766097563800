<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-sheet
        v-bind="attrs"
        v-on="on"
        :color="selected ? `${sheetColor} darken-4` : `${sheetColor} lighten-2`"
        rounded
        elevation="4"
        :height="small ? 40 : 70"
        @click="sheetClick"
        :outlined="selected ? true : false"
        :style="
          (disable ? 'cursor: pointer' : 'cursor:pointer') + ' ;' + sheetStyle
        "
        :class="`${addMargin ? 'ma-2' : ''} ${
          selected ? 'white--text' : 'black--text'
        }`"
      >
        <div class="mt-auto align-center justify-start d-flex px-2 fill-height">
          <v-icon
            v-if="!hideIcon"
            :color="selected ? `${sheetColor} lighten-4` : `${sheetColor}`"
            :large="!small"
            :small="small"
            >{{ iconKey }}
          </v-icon>
          <div
            class="d-flex justify-space-evenly flex-column align-start"
            style="max-width: 75%"
          >
            <span
              :class="
                'condition-type-font ml-3 my-0' +
                (selected ? ' white--text' : '') +
                (subLabel ? ' text-left' : '')
              "
              :style="`font-size: ${fontSize}; line-height: ${fontSize}; font-weight: bolder; width: 100%;`"
              >{{
                itemLabel === undefined ? label : item[itemLabel].toUpperCase()
              }}
            </span>
            <span
              v-if="subLabel"
              :class="
                'condition-type-font ml-3 my-0 text-truncate text-left' +
                (selected ? ' white--text' : '')
              "
              :style="`font-size: ${
                subLabelFontSize ?? '.6rem'
              }; line-height: ${
                subLabelFontSize ?? '.6rem'
              }; font-weight: 100; width: 100%`"
              >{{ subLabel }}
            </span>
          </div>
          <v-icon
            class="ml-3"
            v-if="deleteable"
            color="error"
            :large="!small"
            :small="small"
            @click="() => $emit('delete', item)"
            >mdi-delete
          </v-icon>
        </div>
      </v-sheet>
    </template>
    <span>{{
      itemLabel === undefined ? label : item[itemLabel].toUpperCase()
    }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "SelectionSheet",

  props: {
    type: {
      default: "",
    },

    item: {
      required: true,
    },

    color: {
      default: "grey",
    },

    itemKey: {
      required: true,
    },

    itemLabel: {
      required: false,
      default: undefined,
    },

    iconKey: {
      default: "mdi-broadcast",
    },

    hideIcon: {
      type: Boolean,
      default: false,
    },

    disable: {
      default: false,
    },

    deleteable: {
      type: Boolean,
      default: false,
    },

    sheetStyle: {
      default: "",
    },

    fontSize: {
      type: String,
      default: ".8rem",
    },

    subLabelFontSize: {
      type: String,
      default: ".5rem",
    },

    small: {
      type: Boolean,
      default: false,
    },

    label: {
      default: "",
    },

    subLabel: {
      default: null,
    },

    addMargin: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: "type",
    event: "update-item",
  },

  emits: ["update-item", "click"],

  computed: {
    localType: {
      get: function () {
        return this.type;
      },

      set: function (v) {
        this.$emit("update-item", v);
      },
    },

    selected() {
      return this.type == this.item[this.itemKey];
    },

    sheetColor() {
      return this.disable ? "grey" : this.color;
    },
  },

  methods: {
    sheetClick() {
      if (!this.disable) this.localType = this.item[this.itemKey];
      this.$emit("click", this.item);
    },
  },
};
</script>

<style>
.condition-type-font {
  font-weight: 500;
  letter-spacing: 0.1666666667em !important;
  line-height: 2rem;
  text-transform: uppercase;
  font-family: Roboto, sans-serif !important;
}
</style>
